import { GAME_PAGE_URL, PROMOTION_URL } from '~/constants/lobby'
import {
  IMenuHeader,
  IMenuUserDesktop,
  ISubMenuHeader,
  IMenuWithDraw,
  IMenuDepositBank,
  IMenuDepositDesktop, IMenuDeposit, IMenuMobile, IMenuBottom
} from '~/types/menu.type'
import { PAGE } from '~/constants/router'
import { WITHDRAW_URLS, LOBBY_GAME_URLS, URLS } from '~/config/page-url'
import { TYPE_METHOD } from '~/constants/common'
import { LABEL } from '~/constants/account/history/transactions'

export const PATH_ICON = '/assets/images/components/desktop/menu/'

export const PATH_ICON_ACCOUNT = '/assets/images/components/desktop/menu/account/'

export const LOGO_SITE_IMAGE = '/assets/images/logo.svg'

export const IMAGE_PATH_ACCOUNT = '/assets/images/components/desktop/pages/account/layout/'
export const IMAGE_PATH_DEPOSIT = '/assets/images/components/desktop/pages/account/deposit/'

export const MENUS: IMenuHeader[] = [
  {
    type: 'sports',
    name: 'sports',
    tab: 'tab-sport',
    title: 'Thể thao',
    activeUrl: '/sports'
  },
  {
    type: 'livecasino',
    name: 'casino',
    title: 'Live Casino',
    tab: 'tab-casino',
    activeUrl: GAME_PAGE_URL.all,
    subMenu: []
  },
  {
    type: 'games',
    name: 'game-type',
    title: 'Cổng Games',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.ALL,
    subMenu: []
  },
  {
    name: 'line',
    title: '',
    tab: '',
    activeUrl: '',
    subMenu: undefined
  },
  {
    name: 'khuyen-mai',
    title: 'Khuyến mãi',
    tab: 'tab-promotion',
    activeUrl: '/khuyen-mai',
    subMenu: []
  }
]

export const USER_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Tài khoản',
    url: PAGE.ACCOUNT_PROFILE,
    icon: {
      inactive: PATH_ICON_ACCOUNT + 'help-register.svg',
      active: PATH_ICON_ACCOUNT + 'register-active.svg'
    },
    activeUrl: PAGE.ACCOUNT_PROFILE,
    activeUrlRelative: PAGE.ACCOUNT_PROFILE,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-profile'
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: PAGE.ACCOUNT_DEPOSIT_DA,
    icon: {
      inactive: PATH_ICON_ACCOUNT + 'deposit.svg',
      active: PATH_ICON_ACCOUNT + 'deposit-active.svg'
    },
    activeUrl: PAGE.ACCOUNT_DEPOSIT_DA,
    activeUrlRelative: PAGE.ACCOUNT_DEPOSIT,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-deposit'
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: PAGE.ACCOUNT_WITHDRAW_BANK,
    icon: {
      inactive: PATH_ICON_ACCOUNT + 'withdraw.svg',
      active: PATH_ICON_ACCOUNT + 'withdraw-active.svg'
    },
    activeUrl: PAGE.ACCOUNT_WITHDRAW_BANK,
    activeUrlRelative: PAGE.ACCOUNT_WITHDRAW,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-withdraw'
  },
  {
    id: 4,
    name: 'Lịch sử',
    url: PAGE.ACCOUNT_HISTORY_TRANSACTION,
    icon: {
      inactive: PATH_ICON_ACCOUNT + 'icon-history.svg',
      active: PATH_ICON_ACCOUNT + 'icon-history-active.svg'
    },
    activeUrl: PAGE.ACCOUNT_HISTORY_TRANSACTION,
    activeUrlRelative: PAGE.ACCOUNT_HISTORY_TRANSACTION,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history'
  },
  {
    id: 7,
    name: 'Đăng xuất',
    url: '',
    icon: {
      inactive: PATH_ICON_ACCOUNT + 'icon-logout.svg',
      active: PATH_ICON_ACCOUNT + 'icon-logout-active.svg'
    },
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const MENU_WITHDRAW: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: WITHDRAW_URLS.BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: PAGE.ACCOUNT_WITHDRAW + '/bank'
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: WITHDRAW_URLS.CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: PAGE.ACCOUNT_WITHDRAW + '/crypto'
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: WITHDRAW_URLS.CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: PAGE.ACCOUNT_WITHDRAW + '/card'
  }
]

export const MENU_DEPOSIT: IMenuDeposit[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: PAGE.ACCOUNT_DEPOSIT_DA,
    key: 'nicepayBanks',
    desc: 'Khuyến mãi hấp dẫn',
    icon: 'icon-codepay',
    isHot: true,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: PAGE.ACCOUNT_DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: PAGE.ACCOUNT_DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-codepay2',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: PAGE.ACCOUNT_DEPOSIT_CODEPAY2,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: PAGE.ACCOUNT_DEPOSIT_CRYPTO,
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: PAGE.ACCOUNT_DEPOSIT_CRYPTO,
    isPromotion08: true,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: PAGE.ACCOUNT_DEPOSIT_MOMO,
    key: 'momos',
    desc: 'Momo, Viettel Money',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: PAGE.ACCOUNT_DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    desc: 'Nhiều nhà mạng hỗ trợ',
    queryValue: 'card',
    fullPath: PAGE.ACCOUNT_DEPOSIT_CARD,
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: PAGE.ACCOUNT_DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_DESKTOP: IMenuDepositDesktop[] = [
  {
    name: 'Ngân hàng',
    queryValue: 'codepay',
    fullPath: [PAGE.ACCOUNT_DEPOSIT_BANK,
      PAGE.ACCOUNT_DEPOSIT_DA,
      PAGE.ACCOUNT_DEPOSIT_CODEPAY2],
    key: 'nicepayBanks',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-bank',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    component2: null,
    componentMobile: null,
    componentMobile2: null,
    link: PAGE.ACCOUNT_DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: [PAGE.ACCOUNT_DEPOSIT_CRYPTO],
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/crypto/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: PAGE.ACCOUNT_DEPOSIT_CRYPTO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: [PAGE.ACCOUNT_DEPOSIT_MOMO],
    key: 'momos',
    desc: 'Siêu tiện lợi, nhanh chóng',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/e-wallet/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: PAGE.ACCOUNT_DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    desc: 'Mệnh giá đa dạng',
    queryValue: 'card',
    fullPath: [PAGE.ACCOUNT_DEPOSIT_CARD],
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/card/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: PAGE.ACCOUNT_DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_BANK: IMenuDepositBank[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: PAGE.ACCOUNT_DEPOSIT_DA,
    pathActive: PAGE.ACCOUNT_DEPOSIT_BANK,
    key: 'nicepayBanks',
    icon: 'icon-codepay',
    iconActive: IMAGE_PATH_DEPOSIT + 'icon-codepay-active.svg',
    isHot: true,
    isNew: false,
    link: PAGE.ACCOUNT_DEPOSIT_DA,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: PAGE.ACCOUNT_DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    icon: 'icon-codepay2',
    iconActive: IMAGE_PATH_DEPOSIT + 'icon-gopay-active.svg',
    isHot: false,
    isNew: false,
    link: PAGE.ACCOUNT_DEPOSIT_CODEPAY2,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  }
]

export const MENU_HISTORY = [
  {
    name: LABEL.HISTORY_TRANSACTIONS,
    link: PAGE.ACCOUNT_HISTORY_TRANSACTION,
    icon: 'icon-history-transaction',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/transactions/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/transactions/index.vue'))
  },
  {
    name: LABEL.HISTORY_BETS,
    link: PAGE.ACCOUNT_HISTORY_BET,
    icon: 'icon-history-bets',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/bets/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/bet/index.vue'))
  }
]

export const NOTE = [
  'Nạp / Rút bằng tài khoản chính chủ.',
  'Hỗ trợ chuyển tiền liên ngân hàng.',
  'Lưu lại biên lai để đối chiếu khi cần.'
]

export const USER_MENU_MOBILE: IMenuMobile[] = [
  {
    name: 'Quản lý tài khoản',
    url: PAGE.ACCOUNT_PROFILE,
    icon: 'icon-profile',
    activeUrl: [PAGE.ACCOUNT_PROFILE],
    isLiveChat: false
  },
  {
    name: 'Quản lý ngân hàng',
    url: PAGE.ACCOUNT_BANK,
    icon: 'icon-bank',
    activeUrl: [PAGE.ACCOUNT_PROFILE],
    isLiveChat: false
  },
  {
    name: 'Lịch sử giao dịch',
    url: `${PAGE.ACCOUNT_HISTORY_TRANSACTION}`,
    icon: 'icon-history-transaction',
    activeUrl: [PAGE.ACCOUNT_HISTORY_TRANSACTION],
    isLiveChat: false
  },
  {
    name: 'Lịch sử cá cược',
    url: `${PAGE.ACCOUNT_HISTORY_BET}`,
    icon: 'icon-history-bets',
    activeUrl: [PAGE.ACCOUNT_HISTORY_BET],
    isLiveChat: false
  },
  {
    name: 'Live chat 24/7',
    url: '',
    icon: 'icon-livechat',
    activeUrl: [],
    isLiveChat: true
  }
]

export const BOTTOM_MENU: IMenuBottom[] = [
  {
    name: 'Khuyến mãi',
    url: PAGE.PROMOTION,
    activeUrl: PAGE.PROMOTION,
    icon: PATH_ICON + 'icon-gift.svg'
  },
  {
    name: 'Trang chủ',
    url: PAGE.HOME,
    activeUrl: PAGE.HOME,
    icon: PATH_ICON + 'icon-home.svg'
  },
  {
    name: 'Thể thao',
    url: URLS.LIVESPORT,
    activeUrl: URLS.LIVESPORT,
    icon: PATH_ICON + 'icon-home-2.svg'
  },
  {
    name: 'DA Games',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    activeUrl: LOBBY_GAME_URLS.TABLE_GAME,
    icon: PATH_ICON + 'icon-games.svg'
  },
  {
    name: 'Live casino',
    url: GAME_PAGE_URL.blackjack,
    activeUrl: GAME_PAGE_URL.blackjack,
    icon: PATH_ICON + 'icon-livecasino.svg'
  },
  {
    name: 'Quay số',
    url: LOBBY_GAME_URLS.QUAY_SO,
    activeUrl: LOBBY_GAME_URLS.QUAY_SO,
    icon: PATH_ICON + 'icon-lottery-2.svg'
  }
]

export const LOBBY_SPORTS = [
  {
    title: '',
    imgBackground: '/assets/images/common/menu/goal-sport.webp',
    link: PAGE.CSPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/saba-sport.webp',
    link: PAGE.KSPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/bti-sport.webp',
    link: PAGE.CSPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/the-thao-ao.webp',
    link: PAGE.VIRTUAL_SPORTS
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/e-sport.webp',
    link: PAGE.ESPORT
  },
  {
    title: '',
    imgBackground: '/assets/images/common/menu/lich-thi-dau.webp',
    link: PAGE.ESPORT
  }
]
